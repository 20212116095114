<div
  *ngIf="aboutUs && aboutUs.whoAreWe"
  class="container-fluid p-0 d-flex justify-content-between align-items-center bg-image about-us about-us-image"
  [ngStyle]="{
    background: getBackgroundImageUrl(
      aboutUs &&
        aboutUs.whoAreWe.thumbnail &&
        aboutUs.whoAreWe.thumbnail.location
    ),
  }">
  <div class="content text-white">
    <app-paragraph
          variant="text-white mt-5"
          [html]="aboutUs.whoAreWe.title"></app-paragraph>

    <div class="row">
      <div class="col-sm-12 col-md-8">
        <app-paragraph
          variant="text-white mt-5"
          [html]="aboutUs.whoAreWe.description"></app-paragraph>
      </div>
    </div>
  </div>
</div>
