import { EventEmitter, Injectable } from '@angular/core';
import { dynamicHost } from '@api/app.api';
import { WhitelabelService } from '@core/services/whitelabels.service';
import { AboutUs } from '@modules/pages/home/components/about-us/models/about-us.model';
import { AboutUsDatabaseService } from '@modules/pages/home/components/about-us/services/about-us-db.service';
import { AboutUsService } from '@modules/pages/home/components/about-us/services/about-us.services';

@Injectable({
  providedIn: 'root',
})
export class AboutUsDataService {
  aboutUsUpdated = new EventEmitter<AboutUs>();

  constructor(
    private aboutUsDbService: AboutUsDatabaseService,
    private whitelabelService: WhitelabelService,
    private aboutUsService: AboutUsService
  ) { }

  async getAboutUs(): Promise<void> {
    this.aboutUsService.aboutUs(dynamicHost).subscribe({
      next: async (aboutUs: AboutUs) => {
        this.whitelabelService.changeData(aboutUs.company, aboutUs.copyright);

        this.aboutUsDbService.save(
          aboutUs.company,
          aboutUs.copyright,
          aboutUs.logo
        );

        this.aboutUsUpdated.emit(aboutUs);
      },
    });
  }
}
