import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { Stage } from '@core/models/flow.model';
import { LoadingService } from '@core/services/loading.service';
import { NavigationService } from '@core/services/navigation/navigation.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import {
  CartFlowButtons,
  CartFlowName,
  CartFlowTitle,
} from '@modules/pages/vehicles/components/proposal/cart-item-base/enums/cart-item.base.enum';
import { ProposalResponse } from '@modules/pages/vehicles/components/proposal/cart-item-base/models/proposal.model';
import { CartItemBaseService } from '@modules/pages/vehicles/components/proposal/cart-item-base/services/cart-item-base.service';
import { ProposalService } from '@modules/pages/vehicles/components/proposal/cart-item-base/services/proposal.service';
import {
  CartAllowedComponents,
  CartFlowType,
} from '@modules/pages/vehicles/components/proposal/cart-item-base/types/cart-item-base.type';
import { CartService } from '@modules/pages/vehicles/services/cart/cart.service';
import { CartItem } from '@modules/pages/vehicles/services/cart/models/cart.model';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { SidebarDrawerService } from '@shared/components/sidebar-drawer/services/sidebar-drawer.service';
import { LineComponent } from '@shared/line/line.component';
import { Subject, takeUntil } from 'rxjs';

@LoadingId(LoadingIdEnum.sendProposal)
@Component({
  selector: 'app-cart-item-base',
  standalone: true,
  imports: [
    CommonModule,
    ParagraphComponent,
    ButtonComponent,
    LineComponent,
    ModalComponent,
  ],
  templateUrl: './cart-item-base.component.html',
  styleUrl: './cart-item-base.component.scss',
})
export class CartItemBaseComponent
  extends LoadingBaseComponent
  implements OnInit, OnDestroy
{
  @Input() flowName!: CartFlowName;

  currentFlow!: CartFlowType;
  showEmptyCart = false;
  showNoChargesMessage: boolean = false;
  showClearButton: boolean = true;
  clearButtonLabel: string = CartFlowButtons.clear;
  nextButtonLabel: string = CartFlowButtons.advance;

  cartItems: CartItem[] = [];

  private destroy$ = new Subject<void>();

  constructor(
    protected override loadingService: LoadingService,
    protected cartItemBaseService: CartItemBaseService,
    private navigationService: NavigationService,
    private sidebarDrawerService: SidebarDrawerService,
    private proposalService: ProposalService,
    private cartService: CartService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.initializeRegistrationFlow();
    this.subscribeToCartChanges();
    this.subscribeToStageChanges();
    this.subscribeToDrawerChanges();
    this.handleCartItemUpdate();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initializeRegistrationFlow(): void {
    this.cartItemBaseService.initFlow();

    this.currentFlow = this.cartItemBaseService
      .getFlowService()
      .getFlow(this.flowName);
  }

  subscribeToStageChanges(): void {
    this.cartItemBaseService
      .getFlowService()
      .currentStage$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.updateButtonLabels();
        this.updateShowMessage();
      });
  }

  subscribeToCartChanges(): void {
    this.cartService.cartItems$
      .pipe(takeUntil(this.destroy$))
      .subscribe(items => {
        this.cartItems = items;
        this.showEmptyCart = items.length === 0;
        this.updateButtonLabels();
        this.updateShowMessage();
      });
  }

  subscribeToDrawerChanges(): void {
    this.sidebarDrawerService.isOpen$
      .pipe(takeUntil(this.destroy$))
      .subscribe(isOpen => {
        if (!isOpen) {
          if (
            this.cartItemBaseService.getFlowService().currentStage.title ===
            CartFlowTitle.sent
          )
            this.resetCartAndStage(CartFlowName.cart, true);
          else if (
            this.cartItemBaseService.getFlowService().currentStage.title ===
            CartFlowTitle.observation
          )
            this.cartItemBaseService.getFlowService().clearStage(this.flowName);
        }
      });
  }

  updateButtonLabels(): void {
    switch (this.cartItemBaseService.getFlowService().currentStage.title) {
      case CartFlowTitle.sent:
        this.showClearButton = false;
        this.nextButtonLabel = CartFlowButtons.finish;
        break;
      case CartFlowTitle.observation:
        this.showClearButton = false;
        break;
      default:
        this.showClearButton = !this.showEmptyCart;
        this.clearButtonLabel = CartFlowButtons.clear;
        this.nextButtonLabel = this.showEmptyCart
          ? CartFlowButtons.catalog
          : CartFlowButtons.advance;
        break;
    }
  }

  updateShowMessage(): void {
    this.showNoChargesMessage = false;

    if (
      !this.showEmptyCart &&
      (this.cartItemBaseService.getFlowService().currentStage.title ===
        CartFlowTitle.cart ||
        this.cartItemBaseService.getFlowService().currentStage.title ===
          CartFlowTitle.observation)
    )
      this.showNoChargesMessage = true;
  }

  handleCartItemUpdate(): void {
    this.cartService.cartItemEdit$.subscribe(item => {
      if (item) {
        const {
          itemCart: { id },
          idVehicle,
        } = item;

        if (id)
          this.navigationService.navigateTo('/veiculos/detalhes/', idVehicle);
      }
    });
  }

  onButtonClick(): void {
    if (this.nextButtonLabel === CartFlowButtons.finish) {
      this.closeDrawer();
      this.resetCartAndStage(CartFlowName.cart, true);
    } else if (this.nextButtonLabel === CartFlowButtons.catalog)
      this.openAllVehicles();
    else if (
      this.cartItemBaseService.getFlowService().currentStage.title ===
      CartFlowTitle.observation
    )
      this.sendProposal();
    else this.nextStage();
  }

  onClearCart(): void {
    if (this.showEmptyCart) this.closeDrawer();
    else this.resetCartAndStage(this.flowName);
  }

  openAllVehicles(): void {
    this.closeDrawer();
    this.navigationService.navigateTo('/veiculos');
  }

  nextStage(idProposal?: string): void {
    this.cartItemBaseService.nextStage(idProposal);
  }

  closeDrawer(): void {
    this.sidebarDrawerService.close();
  }

  resetCartAndStage(flowName: CartFlowName, noMessageToast?: boolean): void {
    this.updateShowMessage();
    this.cartService.clearCart(noMessageToast);
    this.cartItemBaseService.getFlowService().clearStage(flowName);
  }

  sendProposal(): void {
    this.proposalService
      .sendProposal({
        note: this.cartService.formSubject.getValue()?.value?.observation,
        proposals: this.cartItems.map(item => {
          return {
            maintenance: item.manutencao,
            uid: item.uid,
            franchise: Number(item.franquia.name),
            terms: [item.rentalPeriod],
            amount: item.quantity,
          };
        }),
      })
      .subscribe({
        next: ({ id }: ProposalResponse) => this.nextStage(id),
      });
  }

  get currentStage(): Stage<CartAllowedComponents> {
    return this.cartItemBaseService.getFlowService().currentStage;
  }
}
