import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingId } from '@core/decorators/loading-id.decorator';
import { LoadingIdEnum } from '@core/enums/loading.enum';
import { LoadingService } from '@core/services/loading.service';
import { SanitizedHtmlService } from '@core/services/sanitized-html.service';
import { ParagraphComponent } from '@modules/auth/shared/paragraph/paragraph.component';
import { PlansData } from '@modules/pages/vehicles/components/rental/rental-plan-option/models/rental-plan-option.model';
import { RentalPlanOptionService } from '@modules/pages/vehicles/components/rental/rental-plan-option/services/rental-plan-option.service';
import { ButtonComponent } from '@shared/components/button/button.component';
import { LoadingBaseComponent } from '@shared/components/loading-base/loading-base.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { PlanDescriptionSkeletonComponent } from '@shared/components/skeleton/plan-description/plan-description-skeleton.component';
import { BRLCurrencyPipe } from '@shared/pipes/brl-currency.pipe';

@LoadingId([LoadingIdEnum.getPlanDescription])
@Component({
  selector: 'app-rental-plan-description',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PlanDescriptionSkeletonComponent,
    ParagraphComponent,
    ButtonComponent,
    ModalComponent,
    BRLCurrencyPipe,
  ],
  templateUrl: './plan-description.component.html',
  styleUrl: './plan-description.component.scss',
})
export class RentalPlanDescriptionComponent
  extends LoadingBaseComponent
  implements OnInit
{
  @Input() planSelected!: string;

  planData!: PlansData;

  constructor(
    protected override loadingService: LoadingService,
    public sanitizedHtmlService: SanitizedHtmlService,
    private rentalPlanOptionService: RentalPlanOptionService
  ) {
    super(loadingService);
  }

  override ngOnInit(): void {
    this.getPlansData();
  }

  ngOnChanges(): void {
    this.getPlansData();
  }

  getPlansData(): void {
    this.rentalPlanOptionService.getPlans().subscribe({
      next: (plan: PlansData[]) =>
      {

        this.planData = plan.find(
          (item) =>
            (item.name === this.planSelected)
        )!

      }})
    }
}
