export const environment = {
  production: false,
  version: 'siteapi',
  admin: 'admin',
  adminLogin: 'login',
  adminEdit: 'edit',
  whiteLabel: 'whitelabels',
  apiDomain: 'addiante',
  apiUrl: 'https://us-central1-addiante-propostas.cloudfunctions.net',
  apiAuth: 'auth',
  apiAuthSend: 'auth/onetimepassword/send',
  apiAuthVerify: 'auth/onetimepassword/verify',
  bdAuth: 'addianteAuthAdminDB',
  logoUrl: 'assets/images/logo/addiante-sempre-primary.png',
  dynamicHost: 'x-dinamic-host',

};
