<div
  class="plan-options mt-4"
  [formGroup]="form"
  [ngClass]="{ 'invalid-required': maintenancePlansRequired }">
  <app-paragraph>Manutenção</app-paragraph>

  <app-plans-skeleton
    [isLoading]="isLoading"
    [quantityRepeteLoading]="2"></app-plans-skeleton>

  <div
    class="mt-3 d-flex align-items-center gap-2"
    *ngIf="!isLoading && maintenancePlans && maintenancePlans.length > 0">
    <label
      *ngFor="let item of maintenancePlans; let i = index"
      [for]="item.tipo"
      [ngClass]="[
        'plan-option btn form-check-label',
        selectedPlan === item ? 'active' : '',
      ]">
      <input
        type="radio"
        class="form-check-input d-block mb-2"
        [id]="item.tipo"
        [value]="item"
        [formControlName]="rentalPlanOptionMaintenancePlans"
        (change)="selectPlan(item)" />

      <div class="paragraph mt-2 d-block">
        <app-paragraph variant="mb-4 fs-14 fw-500 fh-20">
          {{ item.tipo }}
        </app-paragraph>

        <app-button
          label="O que está incluso?"
          variant="resetButton"
          layout="description fs-12 fw-500 fh-16 h-auto text-start"
          (buttonClick)="onOpenModal(item.tipo)">
        </app-button>
      </div>
    </label>
  </div>
</div>

<div
  class="plan-options mt-4"
  [formGroup]="form"
  [ngClass]="{ 'invalid-required': franchisesRequired }">
  <app-paragraph>
    Franquias

    <app-paragraph
      variant="fs-xs text-flat-green mb-0 d-inline"
      *ngIf="!selectedPlan">
      (Escolha o plano)
    </app-paragraph>
  </app-paragraph>

  <app-plans-skeleton
    [isLoading]="!selectedPlan"
    [quantityRepeteLoading]="2"></app-plans-skeleton>

  <div
    class="mt-3 d-flex align-items-center gap-2"
    *ngIf="!isLoading && selectedPlan && franchises && franchises.length > 0">
    <label
      *ngFor="let item of franchises"
      [for]="item.codigo"
      [ngClass]="[
        'plan-option btn form-check-label',
        selectedFranchisesPlan === item ? 'active' : '',
      ]">
      <input
        type="radio"
        class="form-check-input d-block mb-2"
        [id]="item.codigo"
        [value]="item"
        [formControlName]="rentalPlanOptionFranchises"
        (change)="selectFranchisesPlan(item)" />

      <div class="paragraph mt-2 d-block">
        <app-paragraph variant="mb-4 fs-xs fw-500 lh-xs">
          {{ item.franquia }} km/mês
        </app-paragraph>

        <app-paragraph variant="mb-4 excedent fs-xs fw-500 lh-xs">
          Km excedente: {{ item.km_excedente | brlCurrency }}
        </app-paragraph>
      </div>
    </label>
  </div>
</div>

<app-modal
  #modalDescription
  titleLabel="Pacotes de manutenção"
  size="sm" >
  <app-rental-plan-description
    *ngIf="!!howKnowAboutPlan"
    [planSelected]="howKnowAboutPlan"></app-rental-plan-description>
</app-modal>
